import jsCookie from "js-cookie"
import {site_config} from "@/config/index.js";
export default {
	namespaced: true,
	state: {
		token:"",
		user_info:{},
		config:{},
	},
	getters:{
		getToken(state){
			let token = state.token;
			if(!token){
				token = jsCookie.get(`${site_config.cookie_prefix}_view_token`) ;
			}
			return token;
		},
		getUserInfo(state){
			let user_info = state.user_info;
			if(!user_info){
				user_info = jsCookie.get(`${site_config.cookie_prefix}_view_user_info`) ;
				if(user_info){
					user_info = decodeURIComponent(JSON.parse(user_info))
				}
			}
			return user_info;
		}
	},
	mutations: {
		setToken(state,token){
			state.token = token;
			jsCookie.set(`${site_config.cookie_prefix}_view_token`,token)
		},
		setUserInfo(state,user_info){
			state.user_info = user_info;
			jsCookie.set(`${site_config.cookie_prefix}_view_user_info`,encodeURIComponent(JSON.stringify(user_info)))
		},
		setConfig(state,config){
			state.config = config;
		}
	},
}
