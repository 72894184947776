import {request} from '@/common/request/views.js'

export const wechat = {
	jssdk(params){
		return request.get('/api/wechat/jssdk',{params})
	}
}

export const config = {
	get(){
		return request.get('/api/config/get')
	}
}

export const auth = {
	register(data){
		return request.post('/api/auth/register',data)
	},
	login(data){
		return request.post('/api/auth/login',data)
	}
}

export const views = {
	log(data){
		return request.post('/api/view/log',data)
	}
}

export const user = {
	info(params){
		return request.get('/api/user/info', {params})
	}
}
