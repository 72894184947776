import {doViewsRegister} from "@/service/views/common";

export default [
    {
        path:"/",
        component:()=> import('@/layout/Views.vue'),
        children:[
            {
                path: '/',
                component: ()=> import('@/views/Index.vue'),
                meta: {title: '首页'},
            }
        ],
        beforeEnter:(to,from,next)=>{
            next();
        }
    },
    {
        path: '*',
        component: ()=> import('@/views/Error.vue'),
        meta: {title: '错误'},
    }
]
