<template>
	<div class="empty">
		<div class="none" v-if="empty"></div>
		<div class="wrap" @click="click">
			<div v-if="loading" class="image"></div>
			<div class="text">{{ message }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		message: {
			type: String,
			default: "没有更多内容了"
		},
		loading: {
			type: Boolean,
			default: false,
		},
		empty: {
			type: Boolean,
			default: false,
		}
	},
	methods:{
		click(){
			this.$emit('click')
		}
	}
}
</script>
<style lang="scss" scoped>
.empty { margin: 40px auto;text-align: center;font-size: 16px;display: flex;align-items: center;justify-content: center;flex-direction: column;
	.none {width: 120px;height: 120px;margin: 60px auto 40px;background: url("~@/assets/image/components/empty.png") no-repeat center center;background-size: contain;}
	.wrap {display: flex;align-items: center;cursor: pointer;
		@keyframes rotate {
			0% {transform: rotate(0)}
			100% {transform: rotate(360deg)}
		}
		.image {width: 20px;height: 20px;margin-right: 10px;animation: rotate 2s linear infinite;background: url("~@/assets/image/components/loading.png") no-repeat center center;background-size: contain;}
		.text {color: #666;text-align: center;}
	}
}
</style>
