import jsCookie from "js-cookie"
import {site_config} from "@/config/index.js";

export default {
	namespaced: true,
	state: {
		token: "",
		admin_info: {},
		tags: [
			{title: "数据看板", path: "/admin/", fixed: true}
		],
	},
	getters: {
		getToken(state) {
			let token = state.token;
			if (!token) {
				token = jsCookie.get(`${site_config.cookie_prefix}_admin_token`);
			}
			return token;
		}
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
			jsCookie.set(`${site_config.cookie_prefix}_admin_token`, token)
		},
		setAdminInfo(state, admin_info) {
			state.admin_info = admin_info;
		},
		setTags(state, tags) {
			state.tags = tags;
		}
	}
}
