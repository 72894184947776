import {getAdminToken} from "@/service/admin/common.js";

export default [
	{
		path: '/admin/auth/login',
		component: () => import('@/views/admin/auth/Login.vue'),
		meta: {title: '登录'},
	},
	{
		path: "/admin",
		component: () => import('@/layout/Admin.vue'),
		children: [
			{
				path: '/admin',
				component: () => import('@/views/admin/dashboard/Index.vue'),
				meta: {title: '数据看板'},
			},
			{
				path: '/admin/dashboard/chart',
				component: () => import('@/views/admin/dashboard/Chart.vue'),
				meta: {title: '数据大屏'},
			},
			{
				path: '/admin/article',
				component: () => import('@/views/admin/article/Index.vue'),
				meta: {title: '文章管理'},
			},
			{
				path: '/admin/good/category',
				component: () => import('@/views/admin/good/category/Index.vue'),
				meta: {title: '设备分类'},
			},
			{
				path: '/admin/good/index',
				component: () => import('@/views/admin/good/index/Index.vue'),
				meta: {title: '设备清单'},
			},
			{
				path: '/admin/good/drop',
				component: () => import('@/views/admin/good/drop/Index.vue'),
				meta: {title: '报废申请'},
			},
			{
				path: '/admin/work/plan',
				component: () => import('@/views/admin/work/plan/Index.vue'),
				meta: {title: '任务计划'},
			},
			{
				path: '/admin/work/task',
				component: () => import('@/views/admin/work/task/Index.vue'),
				meta: {title: '任务清单'},
			},
			{
				path: '/admin/department',
				component: () => import('@/views/admin/department/Index.vue'),
				meta: {title: '部门管理'},
			},
			{
				path: '/admin/user',
				component: () => import('@/views/admin/user/Index.vue'),
				meta: {title: '用户管理'},
			},
			{
				path: '/admin/feedback',
				component: () => import('@/views/admin/feedback/Index.vue'),
				meta: {title: '用户反馈'},
			},
			{
				path: '/admin/banner',
				component: () => import('@/views/admin/banner/Index.vue'),
				meta: {title: 'Banner管理'},
			},
			{
				path: '/admin/file',
				component: () => import('@/views/admin/file/Index.vue'),
				meta: {title: '文件管理'},
			},
			{
				path: '/admin/admin',
				component: () => import('@/views/admin/admin/Index.vue'),
				meta: {title: '管理员'},
			},
			{
				path: '/admin/config',
				component: () => import('@/views/admin/config/Index.vue'),
				meta: {title: '系统配置'},
			},
			{
				path: '/admin/*',
				name: 'Error',
				component: () => import('@/views/admin/Error.vue'),
				meta: {title: '错误'},
			}
		],
		beforeEnter: (to, from, next) => {
			if (!getAdminToken()) {
				next('/admin/auth/login')
			} else {
				next();
			}
		}
	}
]
